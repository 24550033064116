@import '~@angular/material/prebuilt-themes/indigo-pink.css';

/* * * * * * * * * * * Variables * * * * * * * * * * */

$side-nav-header-color: #ff5722; //$base-accent
$side-nav-header-background: #337ab7;
$side-nav-text-color: #fff; //base-text-color
$nav-menu-border-color: #515159;
$side-nav-min-width: 60px; //$side-panel-min-width
$side-nav-height: 55px;
$side-nav-level-2-min-width: 20px; //$side-panel-min-width
$app-text-police: Roboto,
RobotoFallback,
'Noto Kufi Arabic',
Helvetica,
Arial,
sans-serif;
$containerBackgroundcolor: #f7f7f7;
$containerTitleColor: #313139;
:root {
    --labelFontWeight: bold;
    --accordionTitle: bold;
    --commentTitleSize: 18px;
    --commentTitleWeight: bold;
    --nav-menu-border-color: #{$nav-menu-border-color};
    --side-nav-header-background: #{$side-nav-header-background};
    --side-nav-text-color: #{$side-nav-text-color};
    --app-text-police: #{$app-text-police};
}


/* * * * * * * * * * * App Style * * * * * * * * * * */

html,
body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
}

.dx-fa-button-icon {
    background-color: #3d84c1 !important;
}

.content-style-class {
    padding: 25px;
    padding-top: 5px;
    background-color: var(--container-background-color);
    border-top-style: solid;
    border-top-color: #dddddd;
}

.content-block {
    height: calc(100vh - 149px);
    margin-top: 5px;
    .screen-x-small & {
        margin-left: 20px;
        margin-right: 20px;
    }
    .dx-card {
        box-shadow: 0px 0px 0px !important;
    }
}

.responsive-paddings {
    height: 100%;
    padding: 10px;
}

.add-button-style {
    width: fit-content;
    margin-bottom: 10px;
    .dx-button-has-text .dx-button-content {
        padding: 15px;
        color: white !important;
    }
    .dx-button-mode-contained {
        background-color: var(--side-nav-header-background) !important;
        border-radius: 30px;
    }
    .dx-icon.dx-icon-add::before {
        color: white !important;
    }
}

.drop-down-style {
    .dx-dropdowneditor-icon::before {
        content: url(assets/images/ddarrow.svg) !important;
    }
}

.container {
    height: inherit !important;
    background-color: #ececec;
}


/* * * * * * * * * * * Theme * * * * * * * * * * */


/* Widget */

.dx-widget {
    font-family: $app-text-police;
}


/* Card */

.dx-card {
    border-radius: 4px;
    background-color: #fff;
    overflow: unset !important;
}


/* TreeView */

.dx-checkbox+.dx-treeview-node-container,
.dx-treeview-node-container:first-child {
    margin: 0;
    display: block;
}

.dx-treeview-node-container {
    list-style-position: inside;
    padding: 0;
    margin: 0;
    display: none;
    overflow: hidden;
}

.dx-treeview-node {
    list-style-type: none;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-drag: none;
}

.dx-treeview-item .dx-treeview-item-content span {
    vertical-align: middle;
}

.dx-treeview-item {
    display: block;
    cursor: pointer;
    padding: 13px 8px;
    min-height: 48px;
    line-height: 22px;
}

.dx-treeview-item .dx-treeview-item-content>.dx-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    width: 24px;
    height: 24px;
    background-position: 0 0;
    background-size: 24px 24px;
    padding: 0;
    font-size: 24px;
    text-align: center;
    line-height: 24px;
}

.dx-treeview-item.dx-state-hover {
    background-color: rgba(0, 0, 0, 0.336);
    color: rgba(0, 0, 0, 0.87);
}


/* Toolbar */

.dx-toolbar-items-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.dx-toolbar-item {
    display: table-cell;
    padding: 0 5px;
    vertical-align: middle;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.dx-toolbar-after,
.dx-toolbar-before,
.dx-toolbar-center {
    top: 0;
    display: table;
    height: 100%;
}

.dx-toolbar-after,
.dx-toolbar-before {
    position: absolute;
}

::ng-deep .dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
    padding: 0 40px;
     :host-context(.screen-x-small) & {
        padding: 0 20px;
    }
}

.dx-toolbar-before {
    left: 0;
}

.dx-toolbar-after {
    right: 0;
}

.dx-toolbar .dx-toolbar-items-container {
    height: $side-nav-height !important;
    overflow: visible;
}

.dx-toolbar .dx-toolbar-item:last-child {
    padding: 0;
}

.dx-toolbar .dx-toolbar-label {
    font-size: 20px;
    font-weight: 500;
}


/* Drawer Overlay (Open/Close) */

.dx-overlay-content {
    position: absolute;
    pointer-events: auto;
    z-index: 1000;
    outline: 0;
    overflow: hidden;
}


/* Drawer */

.dx-drawer {
    height: 100%;
    width: 100%;
}

.dx-drawer-wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
}

.dx-drawer-shrink .dx-drawer-panel-content {
    position: relative;
    overflow: hidden;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    height: 100%;
}

.dx-drawer-shrink .dx-drawer-wrapper {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.dx-drawer-shrink .dx-drawer-content {
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

::ng-deep .dx-drawer-content {
    display: flex !important;
}

.dx-drawer-shrink.dx-drawer-bottom .dx-drawer-wrapper,
.dx-drawer-shrink.dx-drawer-top .dx-drawer-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.dx-drawer-overlap .dx-drawer-panel-content {
    overflow: visible;
}

.dx-drawer-overlap .dx-drawer-panel-content.dx-overlay-wrapper {
    right: auto;
    left: auto;
}

.dx-drawer-overlap.dx-drawer-left .dx-drawer-wrapper,
.dx-drawer-overlap.dx-drawer-right .dx-drawer-wrapper {
    overflow-y: visible;
}

.dx-drawer-overlap.dx-drawer-bottom .dx-drawer-wrapper,
.dx-drawer-overlap.dx-drawer-top .dx-drawer-wrapper {
    overflow-x: visible;
}

.dx-drawer-overlap.dx-drawer-right.dx-drawer-expand>.dx-drawer-wrapper>.dx-overlay>.dx-overlay-wrapper>.dx-overlay-content,
.dx-drawer-overlap.dx-drawer-right.dx-drawer-slide>.dx-drawer-wrapper>.dx-overlay>.dx-overlay-wrapper>.dx-overlay-content {
    right: 0;
}

.dx-popup-title {
    color: white !important;
    background-color: var(--side-nav-header-background) !important;
    .dx-icon-close {
        color: white !important;
    }
}

.dx-button-has-text {
    min-width: 64px;
}

.dx-button-has-text .dx-button-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 6px 16px;
}

.dx-button-mode-text .dx-icon {
    color: rgba(0, 0, 0, 0.87);
}

.dx-button-mode-text.dx-state-focused,
.dx-button-mode-text.dx-state-hover {
    background-color: rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dx-button-mode-text.dx-state-active {
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dx-button-mode-text.dx-state-disabled {
    background: 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dx-button-mode-text.dx-state-disabled .dx-icon {
    opacity: 0.6;
}


/* ContextMenu */

.dx-context-menu.dx-overlay-content {
    overflow: inherit;
    position: absolute;
}

.dx-context-menu .dx-menu-item .dx-menu-item-content {
    padding: 5px 10px;
    display: flex;
}

.dx-context-menu .dx-menu-item.dx-menu-item-has-text.dx-menu-item-has-icon .dx-icon {
    margin-right: 15px;
}

.dx-context-menu .dx-menu-items-container {
    padding: 8px 0;
    margin: 0px;
}

.dx-context-menu .dx-submenu {
    background-color: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
    width: max-content;
}

.dx-menu-base .dx-menu-items-container {
    list-style-type: none;
    cursor: pointer;
}

.drop-down-style {
    .dx-dropdowneditor-icon::before {
        content: url(assets/images/ddarrow.svg) !important;
    }
}

.date-box-style {
    .dx-dropdowneditor-icon::before {
        content: url(assets/images/calendar.svg) !important;
    }
}

.accordionStyle {
    .dx-accordion-item-opened>.dx-accordion-item-title::before {
        content: url(assets/images/accordion-open.svg) !important;
        display: block !important;
    }
    .dx-accordion-item-title::before {
        content: url(assets/images/accordion-close.svg) !important;
        display: block !important;
    }
}

.grid-group-by-style {
    .dx-group-row {
        background-color: #eeeeee !important;
        color: #595959 !important;
    }
    .dx-datagrid-group-opened {
        color: #595959 !important;
    }
    .dx-datagrid-group-closed {
        color: #595959 !important;
    }
    .dx-datagrid-group-panel .dx-group-panel-item {
        color: #959595 !important;
        font-weight: 700 !important;
    }
    .dx-datagrid-text-content {
        font-weight: 700 !important;
    }
    .dx-datagrid .dx-datagrid-header-panel .dx-group-panel-item,
    .dx-datagrid .dx-datagrid-header-panel .dx-header-filter {
        border-radius: 4px;
    }
    .dx-datagrid-borders>.dx-datagrid-filter-panel {
        border-radius: 0px 0px 4px 4px;
    }
    .dx-datagrid-borders>.dx-datagrid-headers,
    .dx-datagrid-borders>.dx-datagrid-pager {
        border-radius: 4px 4px 0px 0px;
    }
    .dx-datagrid-rowsview .dx-row {
        color: #595959;
    }
    .dx-header-row {
        text-align-last: left !important;
    }
    .dx-column-indicators {
        float: right !important;
    }
    .dx-datagrid-search-panel {
        margin-left: 0px !important;
    }
    .dx-command-edit-with-icons {
        padding-left: 13px !important;
    }
    .dx-icon-edit::before {
        content: url(assets/images/Edit.svg);
    }
    .dx-icon-trash::before {
        content: url(assets/images/Delete.svg);
    }
    .dx-datagrid .dx-header-filter::before {
        content: url(assets/images/Filter.svg) !important;
    }
    .dx-datagrid .dx-column-indicators .dx-header-filter.dx-header-filter::after {
        top: 6px !important;
        right: 4px !important;
        left: -7px !important;
        content: url(./assets/images/Filter.svg) !important;
    }
    .dx-icon-filter-operation-default::before {
        content: url(assets/images/Search.svg) !important;
    }
    .dx-searchbox .dx-icon-search::before {
        content: url(assets/images/Search.svg) !important;
        display: block !important;
    }
    .dx-icon-save::before {
        content: url(assets/images/save.svg) !important;
    }
    .dx-icon-revert::before {
        content: url(assets/images/revert.svg) !important;
    }
    .dx-icon-column-chooser::before {
        content: url(assets/images/columnChooser.svg) !important;
    }
    .dx-icon-export-to::before {
        content: url(assets/images/exportTo.svg) !important;
    }
    .dx-datebox-calendar .dx-dropdowneditor-icon::before {
        content: url(assets/images/calendar.svg) !important;
    }
    .dx-dropdowneditor-icon::before {
        content: url(assets/images/ddarrow.svg) !important;
    }
    .dx-datagrid-filter-panel-text {
        font-weight: 700;
    }
    .dx-sort {
        padding-right: 15px;
    }
    .dx-info {
        font-weight: 700;
    }
}

.accrodion-style {
    .dx-accordion-item {
        border-radius: 5px;
    }
}

.tileNavArrow {
    float: left;
    margin-top: 8px;
    margin-left: 10px;
    margin-right: 10px;
}

.content-class {
    padding-left: 24px;
    padding-bottom: 40px;
    padding-right: 24px;
    height: 40%;
    background-color: $containerBackgroundcolor;
    border-top-style: solid;
    border-top-color: #dddddd;
}

.tileNav {
    float: left;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: large;
    font-weight: bold;
}

.title-style {
    margin-left: 6px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: $containerTitleColor;
}

.titleDivClass {
    margin: 0px;
    background-color: #337ab7;
    padding: 10px;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.windowTitleBackground {
    .dx-popup-title {
        background-color: #337ab7;
        color: white;
    }
    .dx-icon-close {
        color: white !important;
    }
}

.windowCreateBackground {
    background-color: #337ab7 !important;
    span {
        color: white;
    }
}

.content-block {
    margin: 0 20px 20px 20px;
    height: calc(100vh - 149px);
    max-height: 100%;
    .layout-body {
        padding: 20px;
    }
    .screen-x-small & {
        margin-left: 20px;
        margin-right: 20px;
    }
    .dx-card {
        box-shadow: 0px 0px 0px !important;
    }
}

::ng-deep .dx-icon-customColumnChooserColumn {
    background-image: url(assets/images/User.svg) !important;
    background-repeat: no-repeat;
    background-position: 0px 0px;
}


/* * * * * Form Buttons Style * * * * */

.form-buttons-container {
    display: flex !important;
    justify-content: flex-end !important;
    align-self: flex-end !important;
    .dx-box-flex {
        flex-direction: row !important;
        align-items: flex-end !important;
        gap: 10px;
    }
    .dx-layout-manager .dx-field-item:not(.dx-first-row) {
        padding-bottom: 1px;
    }
}

.submit-button {
    .dx-button-content {
        background-color: #3D84C1 !important;
        border-color: #3D84C1 !important;
        border-radius: 3px !important;
        border-width: 1px;
        color: white;
    }
}


/*** scrollBar***/

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 2px;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(191, 191, 191, 0.7) !important;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -moz-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(191, 191, 191, 0.7) !important;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.05);
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active.dx-scrollable-scroll,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover.dx-scrollable-scroll {
    width: 10px !important;
}

.accrodion-style {
    font-weight: var(--labelFontWeight) !important;
}